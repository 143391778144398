import React, { useState, useEffect } from 'react';
import { graphql } from "gatsby"
import { StaticImage, GatsbyImage, getImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import '../sass/app.scss'

import { useBreakpoint } from "gatsby-plugin-breakpoints"

import bg from "../images/bg.png"
import bgmobile from "../images/bg-mobile.png"

const IndexPage = ({ data }) => {

  const breakpoints = useBreakpoint();
  var image;

  if (breakpoints.mobile) {
    image = bgmobile
  } else {
    image = bg
  }

  return (
    <>
      <Seo seo={data.globalJson.seo} />
      <Layout>
        <div className="fullWidth" style={{ backgroundImage: `url(${image})` }}>
          {breakpoints.md || breakpoints.ipad ? (
            <>
              <div className="div-person-mobile">
                <StaticImage
                  src="../images/person.png"
                  loading="eager"

                  quality={95}
                  formats={["auto", "webp", "avif"]}
                  alt=""
                  style={{}}
                />
                <div className="div-info">
                  <div className="block margin-top-minus">
                    <p className="name KSBold">
                      {data.globalJson.info.name}
                    </p>
                    <p className="info VNormal ">
                      {data.globalJson.info.company}
                    </p>
                    <p className="info VNormal">
                      {data.globalJson.info.department}
                    </p>
                    <p className="info VNormal">
                      {data.globalJson.info.job}
                    </p>

                  </div>

                </div>
              </div>
              <div className="div-social-mobile">
                {data.globalJson.social.map((social, l) => (
                  <a href={social.link} target="_blank" key={"social" + l}>
                    <GatsbyImage image={getImage(social.img)}
                      quality={100}
                      formats={["auto", "webp", "avif"]}
                      alt={social.alt}
                    />
                  </a>

                ))}

              </div>
              <div className="div-clique-mobile">

                <StaticImage
                  src="../images/clique.png"
                  loading="eager"
                  quality={95}
                  formats={["auto", "webp", "avif"]}
                  alt=""
                  style={{}}
                />
              </div>
            </>)
            :
            (

              <>
                <div className="div-person">
                  <StaticImage
                    src="../images/person.png"
                    loading="eager"

                    quality={95}
                    formats={["auto", "webp", "avif"]}
                    alt=""
                    style={{}}
                  />
                </div>
                <div className="div-info">
                  <div className="block margin-top-minus">
                    <p className="name KSBold">
                      {data.globalJson.info.name}
                    </p>
                    <p className="info VNormal ">
                      {data.globalJson.info.company}
                    </p>
                    <p className="info VNormal">
                      {data.globalJson.info.department}
                    </p>
                    <p className="info VNormal">
                      {data.globalJson.info.job}
                    </p>

                  </div>

                </div>
                <div className="div-social">
                  {data.globalJson.social.map((social, l) => (
                    l !== 1 &&
                    <a href={social.link} target="_blank" key={"social" + l}>
                      <GatsbyImage image={getImage(social.img)}
                        quality={100}
                        formats={["auto", "webp", "avif"]}
                        alt={social.alt}
                      />
                    </a>

                  ))}

                </div>
                <div className="div-clique">
                  <StaticImage
                    src="../images/clique.png"
                    loading="eager"
                    quality={95}
                    formats={["auto", "webp", "avif"]}
                    alt=""
                    style={{}}
                  />
                </div>
              </>
            )}
        </div>
      </Layout>
    </>
  )
}

export default IndexPage


export const IndexQuery = graphql`
  query globalJson {
    globalJson {
      info{
        name
        company
        department
        job
      }
      social{
        alt
        link
        img{
          childImageSharp {
            gatsbyImageData
          }
        }
      }
        seo {
        title
        description
        image
        imageAlt
    }
    }
  }
`
